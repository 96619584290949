import { Auth } from "../config/urlConfig";
import { notify } from "../../helpers/helper";

export const registerStep1 =
  (phoneNumber, navigate, grant, invite) => async (dispatch) => {
    dispatch({ type: "REQUESTING_REGISTER_OTP" });
    try {
      const res = await Auth.post(`/account/create/step-1`, { phoneNumber });
      dispatch({
        type: "REQUESTED_REGISTER_OTP",
        payload: { ...res.data, phoneNumber },
      });

      const link = grant
        ? `/confirm-OTP/${res.data.session}?grantId=${grant}`
        : invite
        ? `/confirm-OTP/${res.data.session}?invite=${invite}`
        : `/confirm-OTP/${res.data.session}`;
      navigate(link);
    } catch (error) {
      dispatch({ type: "CANNOT_REQUEST_REGISTER_OTP" });
      notify(error.response ? error.response?.data : error.message, "red");
    }
  };

export const registerStep2 =
  (OTP, session, navigate, grant, invite) => async (dispatch) => {
    dispatch({ type: "CONFIRMING_REGISTER_OTP" });
    try {
      const { data } = await Auth.post(
        `/account/create/step-2${grant ? `?grant=${grant}` : ""}${
          invite ? `?invite=${invite}` : ""
        }`,
        { ...session, OTP }
      );
      dispatch({ type: "CONFIRMED_REGISTER_OTP" });
      if (data.appLink) {
        notify("Access granted successfully! Redirecting...", "green");
        setTimeout(() => {
          window.location.href = data.appLink;
        }, 3000);
        return;
      }

      if (data.token) return navigate("/account-created");

      const link = grant
        ? `/account-information/${session.sessionId}?grantId=${grant}`
        : invite
        ? `/account-information/${session.sessionId}?invite=${invite}`
        : `/account-information/${session.sessionId}`;
      navigate(link);
    } catch (error) {
      dispatch({ type: "CANNOT_CONFIRM_REGISTER_OTP" });
      notify(error.response ? error.response?.data : error.message, "red");
    }
  };

export const registerStep3 =
  (body, grant, invite, navigate) => async (dispatch) => {
    dispatch({ type: "CREATING_ACCOUNT" });
    try {
      if (!body.email) delete body.email;
      const { data } = await Auth.post(
        "/account/create/step-3" +
          (grant ? `?grant=${grant}` : invite ? `?invite=${invite}` : ""),
        body
      );
      if (data.appLink) {
        dispatch({ type: "CREATED_ACCOUNT" });
        notify("Account Created successfully! Redirecting...", "green");
        setTimeout(() => {
          window.location.href = data.appLink;
        }, 3000);
        return;
      }
      navigate("/account-created");
    } catch (error) {
      dispatch({ type: "CANNOT_CREATE_ACCOUNT" });
      notify(error.response ? error.response?.data : error.message, "red");
    }
  };

export const acceptInvitation =
  (invite, rs, errorCallback) => async (dispatch) => {
    dispatch({ type: "CREATING_ACCOUNT" });
    try {
      const { data } = await Auth.put("/account/update/accept-invitation", {
        invite,
        rs,
      });
      if (data.appLink) {
        dispatch({ type: "CREATED_ACCOUNT" });
        notify("Account Created successfully! Redirecting...", "green");
        setTimeout(() => {
          window.location.href = data.appLink;
        }, 3000);
        return;
      }
    } catch (error) {
      if (errorCallback) errorCallback();
      dispatch({ type: "CANNOT_CREATE_ACCOUNT" });
      notify(error.response ? error.response?.data : error.message, "red");
    }
  };

export const isValidGrant = async (grant, setPageLoading, errorCallback) => {
  setPageLoading(true);
  try {
    await Auth.get("/grant/read/isValidGrant/" + grant);
    setPageLoading(false);
  } catch (error) {
    setPageLoading(false);
    if (errorCallback) errorCallback();
  }
};

export const isValidInvite = async (invite, setPageLoading, errorCallback) => {
  setPageLoading(true);
  try {
    await Auth.get("/account/read/isValidInvite/" + invite);
    setPageLoading(false);
  } catch (error) {
    setPageLoading(false);
    if (errorCallback) errorCallback();
  }
};
